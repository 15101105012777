import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { ROUTES } from "../utils/api"
import { SONG_TYPES } from "../utils/types"
import ChooseSong from "../components/ChooseSong"
import MenuWithContent from "../assets/components/MenuWithContent"
import RouteButton from "../assets/components/RouteButton"
import { GraphicEq, MusicNote, Lyrics } from "@mui/icons-material"
import { Grid, Stack } from "@mui/material"
import CategorySelector from "../components/CategorySelector"
import { useChangeCurrentLocation } from "../hooks/contexts/currentLocationContext"
import SearchBar from "../assets/components/SearchBar"

const Library = () => {
    const navigate = useNavigate()
    const curPath = useLocation().pathname
    const [songType, setSongType] = useState<SONG_TYPES | undefined>(undefined)
    const [category, setCategory] = useState<string | undefined>(undefined)
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined)

    const changeCurrentLocation = useChangeCurrentLocation()

    useEffect(() => {
        if (curPath === ROUTES.LIBRARY.MAIN) {
            // Default subpage in library page
            navigate(ROUTES.LIBRARY.MUSIC.fullPath, { replace: true })
        } else if (curPath === ROUTES.LIBRARY.SOUNDBANKS.fullPath) {
            setSongType(SONG_TYPES.SOUNDBANKS)
        } else if (curPath === ROUTES.LIBRARY.MUSIC.fullPath) {
            setSongType(SONG_TYPES.MUSIC)
        } else if (curPath === ROUTES.LIBRARY.KEY.fullPath) {
            setSongType(SONG_TYPES.KEY)
        }
    }, [curPath])

    useEffect(() => {
        setCategory(undefined)
        changeCurrentLocation("Ditt bibliotek")
    }, [songType, changeCurrentLocation])

    let displayedSearchTerm: string = "musik"
    if (songType === SONG_TYPES.MUSIC) {
        displayedSearchTerm = "musik"
    }
    if (songType === SONG_TYPES.SOUNDBANKS) {
        displayedSearchTerm = "ljudlandskap"
    }

    return (
        <MenuWithContent
            showCurrentlyPlaying
            menuItems={
                <>
                    <RouteButton title="Musik" route={ROUTES.LIBRARY.MUSIC.fullPath} icon={MusicNote} />
                    <RouteButton title="Ljudlandskap" route={ROUTES.LIBRARY.SOUNDBANKS.fullPath} icon={GraphicEq} />
                    <RouteButton title="Tonarter" route={ROUTES.LIBRARY.KEY.fullPath} icon={Lyrics} />
                </>
            }
            content={
                <>
                    {songType && (
                        <Stack sx={{ width: "100%", height: "100%" }}>
                            <Grid item sx={{ width: "100%" }}>
                                <SearchBar placeholder={`Sök efter ${displayedSearchTerm}`} setSearchValue={setSearchValue} />
                            </Grid>
                            <Grid item sx={{ width: "100%", pt: 1, px: 1 }}>
                                <CategorySelector type={songType} category={category} setCategory={setCategory} />
                            </Grid>
                            <Grid item xs sx={{ pt: 3 }}>
                                <ChooseSong type={songType} category={category} searchValue={searchValue} />
                            </Grid>
                        </Stack>
                    )}
                </>
            }
        />
    )
}

export default Library
