// import { useCurrentUser } from "../hooks/contexts/currentUserContext"
import { logOut } from "../firebase/authenticationApi"
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom"
import RouteButton from "../assets/components/RouteButton"
import { ROUTES } from "../utils/api"
import BasicButton from "../assets/components/BasicButton"
import { AccountCircleOutlined, LogoutOutlined, LoyaltyOutlined, HelpOutline } from "@mui/icons-material"
import { useEffect } from "react"
import Account from "./settings_subpages/Account"
import License from "./settings_subpages/License"
import About from "./About"
import MenuWithContent from "../assets/components/MenuWithContent"
import { useChangeCurrentLocation } from "../hooks/contexts/currentLocationContext"
import { useIsSmallScreen } from "../hooks/useIsSmallScreen"

const Settings = () => {
    // const currentUser = useCurrentUser()
    const navigate = useNavigate()
    const curPath = useLocation().pathname
    const isSmallScreen = useIsSmallScreen()

    const changeCurrentLocation = useChangeCurrentLocation()

    useEffect(() => {
        if (curPath === ROUTES.SETTINGS.MAIN) {
            // Default subpage in settings page
            navigate(ROUTES.SETTINGS.ACCOUNT.fullPath, { replace: true })
        }
    }, [curPath])

    useEffect(() => {
        changeCurrentLocation("Inställningar")
    }, [changeCurrentLocation])

    return (
        <MenuWithContent
            showCurrentlyPlaying
            extraPadding
            menuItems={
                <>
                    <RouteButton title="Konto" route={ROUTES.SETTINGS.ACCOUNT.fullPath} icon={AccountCircleOutlined} />
                    <RouteButton title="Licens" route={ROUTES.SETTINGS.LICENSE.fullPath} icon={LoyaltyOutlined} />
                    <RouteButton title="Om" route={ROUTES.SETTINGS.ABOUT.fullPath} icon={HelpOutline} />
                    {!isSmallScreen && <BasicButton title="Logga ut" onClick={logOut} icon={LogoutOutlined} />}
                </>
            }
            content={
                <>
                    <Routes>
                        <Route path={ROUTES.SETTINGS.MAIN} element={<Navigate to={ROUTES.SETTINGS.ACCOUNT.fullPath} replace />} />
                        <Route path={ROUTES.SETTINGS.ACCOUNT.subPath} element={<Account />} />
                        <Route path={ROUTES.SETTINGS.ABOUT.subPath} element={<About />} />
                        <Route path={ROUTES.SETTINGS.LICENSE.subPath} element={<License />} />
                    </Routes>
                </>
            }
        />
    )
}

export default Settings
