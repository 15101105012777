import { Dispatch, SetStateAction } from "react"
import { Box, IconButton } from "@mui/material"
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from "@mui/icons-material"
import Text from "./Text"

interface PaginationControlsProps {
    page: number
    setPage: Dispatch<SetStateAction<number>>
    pageCount: number
}

const PaginationControls = ({ page, setPage, pageCount }: PaginationControlsProps) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <IconButton color="inherit" onClick={() => setPage(page - 1)} disabled={page === 1}>
                <ArrowBackIosOutlined />
            </IconButton>
            <Text>{`Sida ${page}/${pageCount}`}</Text>
            <IconButton color="inherit" onClick={() => setPage(page + 1)} disabled={page === pageCount}>
                <ArrowForwardIosOutlined />
            </IconButton>
        </Box>
    )
}

export default PaginationControls
