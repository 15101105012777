import { useEffect, useState } from "react"
import { Card, CardContent, CardMedia, Grid, Stack } from "@mui/material"
import { useChangeCurrentSong, useCurrentSong } from "../hooks/contexts/currentSongContext"
import { Song } from "../utils/types"
import Text from "../assets/components/Text"
import TextRow from "../assets/components/TextRow"

interface ISongCard {
    song: Song
}

const SongCard = ({ song }: ISongCard) => {
    const currentSong = useCurrentSong()
    const changeCurrentSong = useChangeCurrentSong()
    const [isSelected, setIsSelected] = useState<boolean>(false)

    useEffect(() => {
        if (currentSong?.id === song.id) {
            setIsSelected(true)
        } else {
            setIsSelected(false)
        }
    }, [currentSong, song.id])

    return (
        <Card
            onClick={() => {
                changeCurrentSong(song.id)
            }}
            sx={{
                aspectRatio: "2 / 1",
                display: "flex",
                flexDirection: "row",
                background: isSelected ? "white" : "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(11, 5, 4, 0.2))",
                justifyContent: "space-between",
                cursor: "pointer",
                border: isSelected ? "1px solid #77e077" : "1px solid black",
                outline: isSelected ? "2px solid ##77e077" : "",
                boxShadow: isSelected ? "2px 2px 8px rgba(128, 128, 128, 0.8)" : "2px 2px 8px rgba(128, 128, 128, 0.5)",
            }}
        >
            <Grid container>
                <Grid
                    item
                    xs
                    sx={{
                        overflow: "hidden",
                        padding: 2,
                        paddingRight: "0px",
                    }}
                >
                    <CardContent
                        sx={{
                            height: "100%",
                            padding: "0px",
                            "&:last-child": { paddingBottom: "0px" },
                        }}
                    >
                        <Stack
                            sx={{
                                height: "100%",
                                justifyContent: "space-between",
                            }}
                        >
                            <Stack>
                                <Text bold displayedLines={2}>
                                    {song.title}
                                </Text>
                                {song.description && (
                                    <Text size="tiny" displayedLines={2}>
                                        {`${song.description}`}
                                    </Text>
                                )}
                            </Stack>
                            <Stack>
                                {song.keytone && !song.artist && (
                                    <TextRow gap={"tiny"}>
                                        <Text bold size={"tiny"}>
                                            Tonart:
                                        </Text>
                                        <Text prettify size={"tiny"}>
                                            {song.keytone}
                                        </Text>
                                    </TextRow>
                                )}
                                {song.artist && (
                                    <Text hideOverflow size={"tiny"}>
                                        {song.artist}
                                    </Text>
                                )}
                            </Stack>
                        </Stack>
                    </CardContent>
                </Grid>
                <Grid
                    item
                    sx={{
                        width: "50%",
                        aspectRatio: "1 / 1",
                        padding: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                    }}
                >
                    <CardMedia component="img" sx={{ objectFit: "cover" }} image={song.img} alt="Bild av låt" />
                </Grid>
            </Grid>
        </Card>
    )
}

export default SongCard
