import { Button } from "@mui/material"
import { Link, useLocation } from "react-router-dom"
import { SvgIconTypeMap } from "@mui/material"
import { OverridableComponent } from "@mui/types"
import { useEffect, useState } from "react"
import { ROUTES } from "../../utils/api"
import Text from "./Text"

interface LinkButtonProps {
    route: string
    title: string
    icon?: string | OverridableComponent<SvgIconTypeMap<{}, "svg">>
    inHeader?: boolean
}

const orangeLine = {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: "6px",
    right: "6px",
    height: "3.5px",
    backgroundColor: "#ea492e",
    borderRadius: "1px",
    opacity: ".8",
}

const buttonStyles = {
    textTransform: "none",
    color: "black",
    fontFamily: "Raleway",
    fontSize: "18px",
    textDecoration: "none",
    fontWeight: "600px",
    position: "relative",
    overflow: "hidden",
    "&:hover": { backgroundColor: "transparent" },
}

const activeButtonStylesInHeader = {
    ...buttonStyles,
    "&::after": orangeLine,
}

const activeButtonStyles = {
    ...buttonStyles,
    "&::after": { ...orangeLine, opacity: ".5" },
}

const linkStyles = {
    textDecoration: "none",
    color: "inherit",
}

const RouteButton = ({ route, title, icon: Icon, inHeader }: LinkButtonProps) => {
    const [highlighted, setHighlighted] = useState<boolean>(false)
    const [isHovered, setIsHovered] = useState(false)
    const curPath = useLocation().pathname

    useEffect(() => {
        if (curPath === ROUTES.HOME && route === ROUTES.HOME) {
            setHighlighted(true)
        } else if (curPath.includes(route) && route !== ROUTES.HOME) {
            setHighlighted(true)
        } else {
            setHighlighted(false)
        }
    }, [curPath])

    return (
        <>
            <Link to={route} style={linkStyles}>
                <Button sx={highlighted ? (inHeader ? activeButtonStylesInHeader : activeButtonStyles) : buttonStyles} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                    {Icon &&
                        (typeof Icon === "string" ? (
                            <img src={Icon as string} alt={title} style={{ paddingRight: "5px" }} />
                        ) : (
                            <Icon
                                sx={{
                                    marginRight: "5px",
                                    stroke: "#ffffff",
                                    strokeWidth: "0.5px",
                                    fontSize: "20px",
                                }}
                            />
                        ))}
                    <Text bold={highlighted || isHovered}>{title}</Text>
                </Button>
            </Link>
        </>
    )
}

export default RouteButton
