import { useState, useEffect } from "react"
import { Box, Slider, Container } from "@mui/material"
import { usePostMqttChangeMasterVolume } from "../mqtt/mqttHandlerContext"
import Text from "../assets/components/Text"

const MasterVolume = () => {
    const postMqttChangeMasterVolume = usePostMqttChangeMasterVolume()
    const [sliderValue, setSliderValue] = useState(1)

    const handleSlider = (event: Event, newValue: number | number[]) => {
        const newSliderValue = newValue as number
        setSliderValue(newSliderValue)
        postMqttChangeMasterVolume(newSliderValue.toString())
    }

    useEffect(() => {
        const initialValue = localStorage.getItem("masterSliderValue")
        setSliderValue(initialValue ? parseFloat(initialValue) : 1)
    }, [])
    useEffect(() => {
        localStorage.setItem("masterSliderValue", sliderValue.toString())
    }, [sliderValue])

    return (
        <Container
            sx={{
                paddingTop: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box width="70%" sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                <Text size="heading" bold>
                    Master volym
                </Text>
                <Slider
                    sx={{
                        height: "10px",
                        "& .MuiSlider-thumb": {
                            color: "white",
                            height: "30px",
                            width: "10px",
                            borderRadius: "2px",
                        },
                        "& .MuiSlider-track": {
                            color: "#77e077",
                        },
                        "& .MuiSlider-rail": {
                            color: "#acc4e4",
                        },
                        "& .MuiSlider-active": {
                            color: "green",
                        },
                    }}
                    aria-label="Slider"
                    value={sliderValue}
                    min={0}
                    max={1}
                    step={0.1}
                    onChange={handleSlider}
                />
            </Box>
        </Container>
    )
}

export default MasterVolume
