import { MouseEventHandler } from "react"
import { Button, CircularProgress } from "@mui/material"
import { SvgIconTypeMap } from "@mui/material"
import { OverridableComponent } from "@mui/types"

interface BasicButtonProps {
    title: string
    onClick?: MouseEventHandler<HTMLButtonElement>
    type?: ButtonType
    disabled?: boolean
    icon?: string | OverridableComponent<SvgIconTypeMap<{}, "svg">>
    progress?: boolean
    fullWidth?: boolean
}

type ButtonType = "button" | "submit" | "reset" | undefined

const buttonStyles = {
    textTransform: "none",
    color: "black",
    fontFamily: "Raleway",
    fontSize: "16px",
    textDecoration: "none",
    fontWeight: "600px",
    paddingTop: "0px",
    paddingBottom: "0px",
    border: "1.8px solid #555",
}

const BasicButton = ({ title, onClick, type, disabled, icon: Icon, progress, fullWidth }: BasicButtonProps) => {
    return (
        <>
            <Button type={type} disabled={disabled} onClick={onClick} fullWidth={fullWidth} sx={buttonStyles}>
                {Icon &&
                    (typeof Icon === "string" ? (
                        <img src={Icon as string} alt={title} style={{ paddingRight: "5px" }} />
                    ) : (
                        <Icon
                            sx={{
                                marginRight: "5px",
                                stroke: "#ffffff",
                                strokeWidth: "0.5px",
                                fontSize: "20px",
                            }}
                        />
                    ))}
                {title}
                {progress && <CircularProgress size={16} color="inherit" sx={{ marginLeft: "8px" }} />}
            </Button>
        </>
    )
}

export default BasicButton
