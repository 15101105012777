import { useState, ChangeEvent, FormEvent } from "react"
import { Box, TextField, Button, Alert } from "@mui/material"
import * as Yup from "yup"
import { logIn } from "../firebase/authenticationApi"
import { FirebaseError } from "firebase/app"
import { ROUTES } from "../utils/api"
import { useNavigate } from "react-router-dom"
import BasicButton from "../assets/components/BasicButton"
import RouteButton from "../assets/components/RouteButton"
import { ExitToAppOutlined } from "@mui/icons-material"

type AlertColor = "error" | "warning" | "info" | "success"

interface FormData {
    email: string
    password: string
}

const LogIn = () => {
    const validationSchema: Yup.Schema<FormData> = Yup.object().shape({
        email: Yup.string().email("Ogiltig e-postadress").required("Fyll i din e-postadress"),
        password: Yup.string().required("Fyll i ditt lösenord"),
    })

    const initialFormState: FormData = {
        email: "",
        password: "",
    }

    const initialErrors: FormData = {
        email: "",
        password: "",
    }

    const initialAlert = {
        severity: "",
        text: "",
    }

    const [formData, setFormData] = useState(initialFormState)
    const [errors, setErrors] = useState(initialErrors)
    const [alert, setAlert] = useState(initialAlert)
    const [handlingSubmit, setHandlingSubmit] = useState(false)
    const navigate = useNavigate()

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleLogIn = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        // Indicate handling submit
        setHandlingSubmit(true)

        // Reset alert
        setAlert(initialAlert)

        try {
            // Validate form data
            await validationSchema.validate(formData, { abortEarly: false })
            setErrors(initialErrors) // Reset errors

            // Log in user
            await logIn(formData.email, formData.password)
        } catch (error: any) {
            if (Yup.ValidationError.isError(error)) {
                // Validation error
                const newErrors: FormData = { ...initialErrors }
                error.inner.forEach((err: any) => {
                    newErrors[err.path as keyof FormData] = err.message
                })
                setErrors(newErrors)

                // Report validation failure
                setAlert({ severity: "error", text: "Det finns fel i formuläret nedan" })
            } else if (error instanceof FirebaseError) {
                // Firebase error
                console.log("error.code", error.code)
                switch (error.code) {
                    case "auth/invalid-login-credentials":
                        // Report user not found error
                        setAlert({ severity: "error", text: "Ogiltig e-postadress eller lösenord" })
                        break
                    default:
                        // Report general Firebase error
                        setAlert({ severity: "error", text: "Inloggningen misslyckades..." })
                }
            } else {
                // Other error
                // Report general failure
                setAlert({ severity: "error", text: "Inloggningen misslyckades..." })
            }

            // Indicate done handling submit
            setHandlingSubmit(false)
        }
    }

    return (
        <Box
            sx={{
                paddingTop: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                height: "100%",
                width: "330px",
                margin: "0 auto",
                padding: "16px",
            }}
        >
            <h4>Logga in</h4>
            {alert.text ? <Alert severity={alert.severity as AlertColor}>{alert.text}</Alert> : null}
            <form onSubmit={handleLogIn}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "10px",
                    }}
                >
                    <TextField
                        fullWidth
                        label="E-postadress"
                        name="email"
                        variant="standard"
                        value={formData.email}
                        onChange={handleChange}
                        error={!!errors.email}
                        helperText={errors.email}
                        disabled={handlingSubmit}
                    />
                    <TextField
                        fullWidth
                        label="Lösenord"
                        name="password"
                        type="password"
                        variant="standard"
                        value={formData.password}
                        onChange={handleChange}
                        error={!!errors.password}
                        helperText={errors.password}
                        disabled={handlingSubmit}
                    />
                    <BasicButton title="Logga in" type="submit" disabled={handlingSubmit} progress={handlingSubmit} />
                </Box>
            </form>
            <Button
                sx={{
                    textTransform: "none", // Prevent uppercase text
                    color: "black", // Set link color
                    textDecoration: "underline", // Add underline
                    padding: 0, // Remove padding
                    minWidth: "initial", // Allow the button to shrink to the content size
                    background: "none", // Remove background color
                    border: "none", // Remove border
                    cursor: "pointer", // Change cursor to pointer on hover
                    marginTop: "16px",
                }}
                onClick={() => {
                    navigate(ROUTES.REQUEST_PASSWORD_RESET)
                }}
            >
                Glömt lösenordet?
            </Button>
            <Box sx={{ marginTop: "16px" }}>
                <RouteButton title="Skapa nytt konto" route={ROUTES.SIGN_UP} icon={ExitToAppOutlined} />
            </Box>
        </Box>
    )
}

export default LogIn
