import { useEffect, useState } from "react"
import Instruments from "../components/Instruments"
import Section from "../components/Section"
import MasterVolume from "../components/MasterVolume"
import MenuWithContent from "../assets/components/MenuWithContent"
import { useCurrentSong } from "../hooks/contexts/currentSongContext"
import { useChangeCurrentLocation } from "../hooks/contexts/currentLocationContext"
import { Box, SwipeableDrawer } from "@mui/material"
import { KeyboardArrowDown } from "@mui/icons-material"
import Text from "../assets/components/Text"
import BasicButton from "../assets/components/BasicButton"
import { useIsSmallScreen } from "../hooks/useIsSmallScreen"
import AudioPlayer from "../components/audioplayer/AudioPlayer"

const Welcome = () => {
    const currentSong = useCurrentSong()
    const changeCurrentLocation = useChangeCurrentLocation()
    const isSmallScreen = useIsSmallScreen()

    const [isOpen, setIsOpen] = useState(false)

    const toggleDrawer = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        changeCurrentLocation("Hem")
    }, [changeCurrentLocation])

    return (
        <MenuWithContent
            showCurrentlyPlaying
            menuItems={<></>}
            content={
                <>
                    <Instruments />

                    <Box sx={{ display: "flex", justifyContent: "center", padding: 4 }}>
                        <BasicButton fullWidth={isSmallScreen} title="Öppna Mixer" onClick={toggleDrawer} />
                    </Box>
                    <SwipeableDrawer
                        anchor="bottom"
                        open={isOpen}
                        onClose={toggleDrawer}
                        onOpen={toggleDrawer}
                        sx={{
                            "& .MuiDrawer-paper": {
                                borderTopLeftRadius: "10px",
                                borderTopRightRadius: "10px",
                            },
                        }}
                    >
                        <Box sx={{ height: "auto" }}>
                            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                <KeyboardArrowDown onClick={toggleDrawer} />
                                <Text size="giant">Mixer</Text>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 5, pb: 5 }}>
                                <MasterVolume />
                                {currentSong?.hasSections && <Section />}
                                {currentSong?.hasBackingTrack && <AudioPlayer />}
                            </Box>
                        </Box>
                    </SwipeableDrawer>
                </>
            }
        />
    )
}

export default Welcome
