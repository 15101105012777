import logo from "../assets/funki_VAG_1280_red.png"
import { AppBar, Toolbar, Box } from "@mui/material"
import { logOut } from "../firebase/authenticationApi"
import { ROUTES } from "../utils/api"
import { useIsSmallScreen } from "../hooks/useIsSmallScreen"
import { CurrentUser, useCurrentUser } from "../hooks/contexts/currentUserContext"
import { AdminPanelSettingsOutlined, CottageOutlined, LibraryMusicOutlined, LoginRounded, SettingsOutlined, LogoutOutlined } from "@mui/icons-material"
import RouteButton from "../assets/components/RouteButton"
import { useCurrentLocation } from "../hooks/contexts/currentLocationContext"
import BasicButton from "../assets/components/BasicButton"

const Navbar = () => {
    const currentUser = useCurrentUser()
    const isSmallScreen = useIsSmallScreen()
    const currentLocation = useCurrentLocation()

    return isSmallScreen ? <MobileNavbar currentUser={currentUser} currentLocation={currentLocation} /> : <DesktopNavbar currentUser={currentUser} />
}

const MobileNavbar = ({ currentUser, currentLocation }: { currentUser: CurrentUser; currentLocation: string }) => {
    return (
        <AppBar position="fixed" sx={{ top: "auto", bottom: 0, backgroundColor: "white", zIndex: 1000 }}>
            <Toolbar
                sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                }}
            >
                {currentUser?.customer &&
                    (currentLocation === "Inställningar" ? (
                        <BasicButton title="Logga ut" onClick={logOut} icon={LogoutOutlined} />
                    ) : (
                        <>
                            <RouteButton inHeader route={ROUTES.LIBRARY.MUSIC.fullPath} title="Bibliotek" icon={LibraryMusicOutlined} />
                            <RouteButton inHeader route={ROUTES.HOME} title="Hem" icon={CottageOutlined} />
                        </>
                    ))}
            </Toolbar>
        </AppBar>
    )
}

const DesktopNavbar = ({ currentUser }: { currentUser: CurrentUser }) => {
    return (
        <AppBar position="fixed" sx={{ top: 0, bottom: "auto", backgroundColor: "white", zIndex: 1000 }}>
            <Toolbar
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    {currentUser?.customer && (
                        <>
                            <RouteButton inHeader route={ROUTES.LIBRARY.MUSIC.fullPath} title="Bibliotek" icon={LibraryMusicOutlined} />
                            <RouteButton inHeader route={ROUTES.HOME} title="Hem" icon={CottageOutlined} />
                        </>
                    )}
                </Box>

                <Box
                    sx={{
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)",
                    }}
                >
                    <img src={logo} alt="Logo" style={{ height: "45px" }} />
                </Box>

                <Box>
                    {currentUser ? (
                        <>
                            {!currentUser.customer && <RouteButton inHeader route={ROUTES.ACTIVATE_ACCOUNT} title="Aktivera konto" />}
                            <RouteButton inHeader route={ROUTES.SETTINGS.MAIN} title="Inställningar" icon={SettingsOutlined} />
                            {currentUser.admin && <RouteButton inHeader route={ROUTES.ADMIN.MAIN} title="Admin" icon={AdminPanelSettingsOutlined} />}
                        </>
                    ) : (
                        <>
                            <RouteButton inHeader route={ROUTES.LOG_IN} title="Logga in" icon={LoginRounded} />
                        </>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default Navbar
