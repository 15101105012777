import "./App.css"
import { BrowserRouter as Router } from "react-router-dom"
import { CurrentUserProvider, useCurrentUser } from "./hooks/contexts/currentUserContext"
import { CurrentSongProvider } from "./hooks/contexts/currentSongContext"
import { MqttHandlerProvider } from "./mqtt/mqttHandlerContext"
// import Footer from "./components/Footer"
import Loading from "./pages/Loading"
import ContentsLoggedIn from "./components/ContentsLoggedIn"
import ContentsNoUser from "./components/ContentsNoUser"
import { CurrentLocationProvider } from "./hooks/contexts/currentLocationContext"

const AppContents = () => {
    const currentUser = useCurrentUser()

    return (
        <>
            {currentUser !== undefined ? (
                <>
                    {currentUser ? (
                        <MqttHandlerProvider>
                            <CurrentSongProvider>
                                <CurrentLocationProvider>
                                    <ContentsLoggedIn />
                                </CurrentLocationProvider>
                            </CurrentSongProvider>
                        </MqttHandlerProvider>
                    ) : (
                        <ContentsNoUser />
                    )}
                </>
            ) : (
                <Loading />
            )}
        </>
    )
}

function App() {
    return (
        <Router>
            <CurrentUserProvider>
                <AppContents />
            </CurrentUserProvider>
        </Router>
    )
}

export default App
