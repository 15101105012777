import { Box } from "@mui/material"
import IconHeart from "../assets/icons/IconHeart.png"

const About = () => {
    return (
        <Box
            sx={{
                paddingTop: 5,
                paddingBottom: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                height: "100%",
            }}
        >
            <h3>With {<img src={IconHeart} alt="Hjärta" style={{ width: "24px", height: "24px" }} />} from Funki</h3>
            <h3>v. 2.0.0</h3>
        </Box>
    )
}

export default About
