import { Box } from "@mui/material"
import BasicAccordion from "../assets/components/BasicAccordion"
import Text from "../assets/components/Text"
import TextRow from "../assets/components/TextRow"
import { useCurrentSong, useCurrentlyPlayingIsExpanded, useSetCurrentlyPlayingIsExpanded } from "../hooks/contexts/currentSongContext"
import { Song } from "../utils/types"

interface CurrentlyPlayingAccordionProps {
    song: Song
    expanded?: boolean
    onChange: (isExpanded: boolean) => void
}

const CurrentlyPlayingAccordion = ({ song, expanded, onChange }: CurrentlyPlayingAccordionProps) => {
    return (
        <Box>
            <BasicAccordion
                reverse
                noBorder
                compact
                expanded={expanded}
                onChange={(isExpanded) => {
                    onChange(isExpanded)
                }}
                summary={
                    song.artist ? (
                        <Text bold size={"small"}>
                            {song.artist}
                        </Text>
                    ) : (
                        <Text bold prettify size={"small"}>
                            {song.category}
                        </Text>
                    )
                }
                details={
                    <>
                        {song.genre && (
                            <TextRow gap={"tiny"}>
                                <Text bold size={"tiny"}>
                                    Genre:
                                </Text>
                                <Text prettify size={"tiny"}>
                                    {song.genre}
                                </Text>
                            </TextRow>
                        )}
                        {song.keytone && (
                            <TextRow gap={"tiny"}>
                                <Text bold size={"tiny"}>
                                    Tonart:
                                </Text>
                                <Text prettify size={"tiny"}>
                                    {song.keytone}
                                </Text>
                            </TextRow>
                        )}
                        {song.tempo && (
                            <TextRow gap={"tiny"}>
                                <Text bold size={"tiny"}>
                                    Tempo:
                                </Text>
                                <Text prettify size={"tiny"}>
                                    {song.tempo}
                                </Text>
                            </TextRow>
                        )}
                    </>
                }
            />
            <Text bold size={"standard"}>
                {song.title}
            </Text>
        </Box>
    )
}

const CurrentlyPlaying = () => {
    const currentSong = useCurrentSong()
    const currentlyPlayingIsExpanded = useCurrentlyPlayingIsExpanded()
    const setCurrentlyPlayingIsExpanded = useSetCurrentlyPlayingIsExpanded()

    return (
        <>
            {currentSong ? (
                <CurrentlyPlayingAccordion
                    song={currentSong}
                    expanded={currentlyPlayingIsExpanded}
                    onChange={(isExpanded) => {
                        setCurrentlyPlayingIsExpanded(isExpanded)
                    }}
                />
            ) : null}
        </>
    )
}

export default CurrentlyPlaying
