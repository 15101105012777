import { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react"
import { Client } from "paho-mqtt"
import { MQTT_API } from "../utils/api"
import { useCurrentUser } from "../hooks/contexts/currentUserContext"
import { Instrument } from "../utils/types"

interface MqttHandlerContextProps {
    mqttIsConnected: boolean
    connectedInstruments: Map<string, ConnectedInstrumentInfo>
    postChangeSong: (message: string, instrumentId?: string) => void
    postBackingtrack: (message: string, instrumentId?: string) => void
    postMetronome: (message: string, instrumentId?: string) => void
    postChangeSection: (message: string, instrumentId?: string) => void
    postChangeVolume: (message: string, instrumentId: string) => void
    postChangeMasterVolume: (message: string) => void
}

interface ConnectedInstrumentInfo {
    type: string
    lastOnlineTimestamp?: number
}

const MqttHandlerContext = createContext<MqttHandlerContextProps>({
    mqttIsConnected: false,
    connectedInstruments: new Map<string, ConnectedInstrumentInfo>(),
    postChangeSong: () => {},
    postBackingtrack: () => {},
    postMetronome: () => {},
    postChangeSection: () => {},
    postChangeVolume: () => {},
    postChangeMasterVolume: () => {},
})

const generateRandomClientId = () => {
    const randomNumber = Math.floor(Math.random() * 90000) + 10000
    return randomNumber.toString()
}

export const MqttHandlerProvider = ({ children }: { children: ReactNode }) => {
    const currentUser = useCurrentUser()
    const [mqttClient, setMqttClient] = useState<Client | undefined>(undefined)
    const [mqttIsConnected, setMqttIsConnected] = useState<boolean>(false)
    const [connectedInstruments, setConnectedInstruments] = useState<Map<string, ConnectedInstrumentInfo>>(new Map<string, ConnectedInstrumentInfo>())
    const connectedInstrumentsRef = useRef(connectedInstruments)

    const options = {
        host: "333ab3d8a3304b908eb458b1479ed3b4.s1.eu.hivemq.cloud",
        port: 8884,
        protocol: "wss",
        clientId: "web" + generateRandomClientId(),
        userName: MQTT_API.CREDITS.USERNAME,
        password: MQTT_API.CREDITS.PASSWORD,
        keepAliveInterval: 30,
        cleanSession: false,
    }

    ////// USE EFFECTS //////

    useEffect(() => {
        if (!currentUser?.customer) {
            console.log("No customer connected, skipping MQTT handler setup")
            return
        } else {
            console.log("Customer found, initializing MQTT handler")

            // Create MQTT client
            const client = new Client(options.host, options.port, options.clientId)

            // Configure MQTT client
            configureMqttClient(client)

            // Set an interval to check if instruments have lost connection
            // const intervalId = configureIntervalCheckForInstruments()

            // Cleanup when component unmounts
            return () => {
                // Cleanup MQTT client
                if (client.isConnected()) {
                    // Update onConnectionLost to not reconnect
                    client.onConnectionLost = (error) => {
                        setMqttIsConnected(false)
                    }
                    // Disconnect MQTT client
                    client.disconnect()
                    console.log("Disconnected MQTT client on component unmount")
                }

                // Clear the interval
                // clearInterval(intervalId)
                console.log("Cleared interval for checking timed out instruments on component unmount")
            }
        }
    }, [currentUser])

    useEffect(() => {
        if (mqttClient) {
            console.log("mqttClient is connected", mqttClient.isConnected())
            setMqttIsConnected(mqttClient.isConnected())

            if (currentUser?.customer?.id) {
                // Subscribe to topics
                const customerId = currentUser?.customer?.id
                mqttClient.subscribe(`${customerId}/${MQTT_API.TOPIC_ACTION.ON_START}/+`)
                mqttClient.subscribe(`${customerId}/${MQTT_API.TOPIC_ACTION.HEARTBEAT}/+`)
                currentUser?.customer?.instruments.forEach((instrument) => {
                    mqttClient.subscribe(`/${instrument.deviceId}/status`)
                })
            }
        } else {
            setMqttIsConnected(false)
        }
    }, [mqttClient])

    useEffect(() => {
        // Needed for use in interval for checking timed out instruments
        connectedInstrumentsRef.current = connectedInstruments
    }, [connectedInstruments])

    ////// PRIVATE METHODS //////

    const configureMqttClient = (client: Client) => {
        // Set on conneciton lost
        client.onConnectionLost = (error) => {
            console.error(`MQTT connection lost: ${error.errorMessage}`)
            setMqttIsConnected(false)
            setMqttClient(undefined)

            // Reconnect
            connectMqttClient(client)
        }

        // Initial MQTT client connect
        connectMqttClient(client)

        // Set on message arrived
        client.onMessageArrived = (message: any) => {
            currentUser?.customer?.instruments.forEach((instrument) => {
                if (message.destinationName.includes(`/${instrument.deviceId}/status`) && message.payloadString === "ONLINE") {
                    setConnectedInstruments((prevMap) => {
                        const updatedMap = new Map(prevMap)
                        const prevValue: ConnectedInstrumentInfo | undefined = updatedMap.get(instrument.deviceId)
                        if (prevValue) {
                            updatedMap.set(instrument.deviceId, { ...prevValue })
                        } else {
                            const instrumentObj: Instrument | undefined = currentUser?.customer?.instruments.find((obj: Instrument) => obj.deviceId === instrument.deviceId)
                            if (instrumentObj) {
                                updatedMap.set(instrument.deviceId, { type: instrumentObj.type })
                            } else {
                                // Handle unknown instrument trying to connect
                                console.error(`Unknown instrument: got a message on ${message.destinationName} for an instrument with id ${instrument.deviceId}`)
                            }
                        }
                        return updatedMap
                    })
                }

                if (message.destinationName.includes(`/${instrument.deviceId}/status`) && message.payloadString === "OFFLINE") {
                    setConnectedInstruments((prevMap) => {
                        const updatedMap = new Map(prevMap)
                        updatedMap.delete(instrument.deviceId)
                        return updatedMap
                    })
                }
            })
        }
    }

    const connectMqttClient = (client: Client) => {
        client.connect({
            useSSL: true,
            onSuccess: () => {
                console.log("Successfully connected MQTT client")
                setMqttClient(client)
            },
            onFailure: (error) => {
                console.log("Connection failed:", error.errorMessage)
                // Handle what should happen if connection fail
            },
            userName: options.userName,
            password: options.password,
            keepAliveInterval: options.keepAliveInterval,
        })
    }

    ////// METHODS FOR POSTING ON TOPICS //////

    const postChangeSong = (message: string, instrumentId?: string) => {
        const payLoad = {
            message: message,
            timeStamp: Date.now(),
        }

        if (mqttClient?.isConnected()) {
            Array.from(connectedInstruments, ([instrumentId]) => mqttClient.send(`/${instrumentId}/${MQTT_API.TOPIC_ACTION.SONG}`, JSON.stringify(payLoad), 1, false))
        } else {
            console.error("MQTT client not connected: unable to send change song message")
        }
    }

    // export function handleChangeSong(message: SONGS) {
    // 	let changeSongTopic = MQTT_API.INSTRUMENTS.ALL + MQTT_API.TOPIC.SONG
    // 	const payLoad = {
    // 	  message: message,
    // 	  timeStamp: Date.now(),
    // 	}
    // 	mqttClient?.send(changeSongTopic, JSON.stringify(payLoad), 1, false)
    //   }

    const postBackingtrack = (message: string, instrumentId?: string) => {
        if (mqttClient?.isConnected()) {
            Array.from(connectedInstruments, ([instrumentId]) => mqttClient.send(`/${instrumentId}/${MQTT_API.TOPIC_ACTION.BACKINGTRACK}`, message, 1, false))
        } else {
            console.error("MQTT client not connected: unable to send change song message")
        }
    }

    const postMetronome = (message: string, instrumentId?: string) => {
        if (mqttClient?.isConnected()) {
            Array.from(connectedInstruments, ([instrumentId]) => mqttClient.send(`/${instrumentId}/${MQTT_API.TOPIC_ACTION.METRONOME}`, message, 1, false))
        } else {
            console.error("MQTT client not connected: unable to send change song message")
        }
    }

    const postChangeSection = (message: string, instrumentId?: string) => {
        if (mqttClient?.isConnected()) {
            Array.from(connectedInstruments, ([instrumentId]) => mqttClient.send(`/${instrumentId}/${MQTT_API.TOPIC_ACTION.SECTION}`, message, 1, false))
        } else {
            console.error("MQTT client not connected: unable to send change song message")
        }
    }

    const postChangeVolume = (message: string, instrumentId?: string) => {
        if (mqttClient?.isConnected()) {
            mqttClient.send(`/${instrumentId}/${MQTT_API.TOPIC_ACTION.VOLUME}`, message, 0, false)
        } else {
            console.error("MQTT client not connected: unable to send change volume message")
        }
    }

    const postChangeMasterVolume = (message: string) => {
        if (mqttClient?.isConnected()) {
            Array.from(connectedInstruments, ([instrumentId]) => mqttClient.send(`/${instrumentId}/${MQTT_API.TOPIC_ACTION.MASTER_VOLUME}`, message, 1, false))
        } else {
            console.error("MQTT client not connected: unable to send change song message")
        }
    }

    return (
        <MqttHandlerContext.Provider
            value={{
                mqttIsConnected,
                connectedInstruments,
                postChangeSong,
                postBackingtrack,
                postMetronome,
                postChangeSection,
                postChangeVolume,
                postChangeMasterVolume,
            }}
        >
            {children}
        </MqttHandlerContext.Provider>
    )
}

export const useConnectedInstruments = () => useContext(MqttHandlerContext).connectedInstruments
export const useMqttIsConnected = () => useContext(MqttHandlerContext).mqttIsConnected
export const usePostMqttChangeSong = () => useContext(MqttHandlerContext).postChangeSong
export const usePostMqttBackingtrack = () => useContext(MqttHandlerContext).postBackingtrack
export const usePostMqttMetronome = () => useContext(MqttHandlerContext).postMetronome
export const usePostMqttChangeSection = () => useContext(MqttHandlerContext).postChangeSection
export const usePostMqttChangeVolume = () => useContext(MqttHandlerContext).postChangeVolume
export const usePostMqttChangeMasterVolume = () => useContext(MqttHandlerContext).postChangeMasterVolume
