import React, { useEffect, useState } from "react"
import buttons from "../images/Instrument-Buttons.png"
import levers from "../images/Instrument-Levers.png"
import joysticks from "../images/Instrument-Joysticks.png"
import touch from "../images/Instrument-Touch.png"
import { VolumeUp, VolumeMute, VolumeDown, VolumeOff } from "@mui/icons-material"

import { Box, Container, Slider } from "@mui/material"

import { useIsSmallScreen } from "../hooks/useIsSmallScreen"
import { useConnectedInstruments, usePostMqttChangeVolume } from "../mqtt/mqttHandlerContext"

// Returnerar korrekt bild beroende på vilket instrument id vi anropar med
function handleImageSource(type: string): string {
    switch (type) {
        case "buttons":
            return buttons
        case "touch":
            return touch
        case "joysticks":
            return joysticks
        case "levers":
            return levers
        default:
            return buttons
    }
}
interface ConnectedInstrumentInfo {
    type: string
    lastOnlineTimestamp?: number
}
const Instruments = () => {
    const postMqttChangeVolume = usePostMqttChangeVolume()
    const connectedInstruments = useConnectedInstruments()
    // const connectedInstruments: Map<string, ConnectedInstrumentInfo> = new Map([
    //     ["joysticks", { type: "joysticks", lastOnlineTimestamp: Date.now() - 3600000 }],
    //     ["buttons", { type: "buttons", lastOnlineTimestamp: Date.now() - 7200000 }],
    //     ["touch", { type: "touch" }],
    //     ["joysticks2", { type: "joysticks", lastOnlineTimestamp: Date.now() - 3600000 }],
    // ])

    const isSmallScreen = useIsSmallScreen()
    // Use state to manage the volume values for each instrument
    const [instrumentVolumes, setInstrumentVolumes] = useState<{
        [key: string]: number
    }>({})

    const handleSlider = (event: React.ChangeEvent<HTMLInputElement>, instrument: any) => {
        const newVolume = parseFloat(event.target.value)
        setInstrumentVolumes((prevVolumes) => ({
            ...prevVolumes,
            [instrument]: newVolume,
        }))
        postMqttChangeVolume(newVolume.toString(), instrument)
    }

    // Fetch initial volume values from storage when the component mounts
    useEffect(() => {
        const storedVolumesString = localStorage.getItem("instrumentVolumes")
        if (storedVolumesString) {
            const storedVolumes = JSON.parse(storedVolumesString)
            setInstrumentVolumes(storedVolumes)
        }
    }, [])

    // Save volume values to storage whenever they change
    useEffect(() => {
        localStorage.setItem("instrumentVolumes", JSON.stringify(instrumentVolumes))
    }, [instrumentVolumes])

    return (
        <Container
            sx={{
                paddingTop: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // minHeight: "50vh",
            }}
        >
            {connectedInstruments.size > 0 ? (
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr",
                        gap: "1rem",
                    }}
                >
                    {Array.from(connectedInstruments, ([instrumentId, instrumentInfo]) => (
                        <Box key={instrumentId} sx={{ display: "flex", alignItems: "center" }}>
                            <Box height={"100%"} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                {/* <VolumeUp fontSize="small" sx={{ mb: 1 }} /> */}
                                <VolumeIcon value={instrumentVolumes[instrumentId]} />
                                <Slider
                                    sx={{
                                        width: "10px",
                                        "& .MuiSlider-thumb": {
                                            color: "white",
                                            height: "5px",
                                            borderRadius: "2px",
                                        },
                                        "& .MuiSlider-track": {
                                            color: "#77e077",
                                        },
                                        "& .MuiSlider-rail": {
                                            color: "#acc4e4",
                                        },
                                        "& .MuiSlider-active": {
                                            color: "green",
                                        },
                                    }}
                                    aria-label="Slider"
                                    orientation="vertical"
                                    valueLabelDisplay="auto"
                                    value={instrumentVolumes[instrumentId] ?? 1}
                                    min={0}
                                    max={1}
                                    step={0.1}
                                    onChange={(event: any) => handleSlider(event, instrumentId)}
                                />
                            </Box>

                            <Box>
                                <img src={handleImageSource(instrumentInfo.type)} alt="Bild på instrument" width="80%" />
                                {/* <p>deviceId: {instrumentId}</p> */}
                            </Box>
                        </Box>
                    ))}
                </Box>
            ) : (
                <Box>
                    <h4>Väntar på Funki instrument...</h4>
                </Box>
            )}
        </Container>
    )
}

const VolumeIcon = ({ value }: { value: number }) => {
    if (value === 0) {
        return <VolumeOff />
    }
    if (value <= 0.3 && value > 0) {
        return <VolumeMute />
    }
    if (value < 0.3 && value >= 0.7) {
        return <VolumeDown />
    }
    if (value > 0.7) {
        return <VolumeUp />
    } else {
        return <VolumeDown />
    }
}

export default Instruments
