import { useState, ChangeEvent, FormEvent, useEffect } from "react"
import { TextField, Alert, Box, LinearProgress } from "@mui/material"
import { FirebaseError } from "firebase/app"
import { updateCustomerForUser, validActivationCode } from "../firebase/databaseApi"
import { useCurrentUser, useReloadCurrentUser } from "../hooks/contexts/currentUserContext"
import BasicButton from "../assets/components/BasicButton"
import LinkButton from "../assets/components/LinkButton"

interface ActivateAccountProps {
    inline?: boolean
}

const defaultSx = {
    paddingTop: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
    flexDirection: "column",
    height: "100%",
    width: "330px",
    margin: "0 auto",
    padding: "16px",
}

const inlineSx = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
}

type AlertSeverity = "error" | "warning" | "info" | "success"

function sleep(seconds: number) {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000))
}

const ActivateAccount = ({ inline = false }: ActivateAccountProps) => {
    const initialFieldState = ""
    const initialError = ""
    const initialAlert = {
        severity: "",
        text: "",
    }

    const [activationCode, setActivationCode] = useState(initialFieldState)
    const [error, setError] = useState(initialError)
    const [alert, setAlert] = useState(initialAlert)
    const [accountActivated, setAccountActivated] = useState(false)
    const [handlingSubmit, setHandlingSubmit] = useState(false)
    const currentUser = useCurrentUser()
    const reloadCurrentUser = useReloadCurrentUser()

    useEffect(() => {
        setError(initialError) // Reset errors if activation code is edited
    }, [activationCode])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setActivationCode(e.target.value)
    }

    const handleActivate = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        // Indicate handling submit
        setHandlingSubmit(true)

        // Reset alert
        setAlert(initialAlert)

        // Reset error
        setError(initialError)

        try {
            // Try to activate account
            if (await validActivationCode(activationCode)) {
                // The activation code is valid

                // Update customer for the user in database
                await updateCustomerForUser(currentUser?.auth?.uid, activationCode)

                // Set account activated
                setAccountActivated(true)

                // Report success
                setAlert({ severity: "success", text: `Aktiveringen lyckades` })

                // Stylistic 3-second sleep
                await sleep(3)

                // Trigger reload of current user to fetch new data
                reloadCurrentUser()
            } else {
                // Activation code is invalid

                // Feedback user
                setError(`Den angivna aktiveringskoden är ogiltig`)
            }
        } catch (error: any) {
            if (error instanceof FirebaseError) {
                // Firebase error

                // Feedback user
                setAlert({ severity: "error", text: `Något gick fel vid aktiveringen, testa igen eller kontakta oss på Funki om problemet kvarstår` })
            } else {
                // Other error

                // Feedback user
                setAlert({ severity: "error", text: `Något gick fel vid aktiveringen, testa igen eller kontakta oss på Funki om problemet kvarstår` })
            }
        }

        // Indicate done handling submit
        setHandlingSubmit(false)
    }

    return (
        <Box sx={inline ? inlineSx : defaultSx}>
            <h4>Ditt konto är inte kopplat till en licens ännu</h4>
            <p>För att aktivera ditt konto och ansluta till en licens, skriv in aktiveringskoden för licensen nedan.</p>

            {alert.text ? (
                <Alert
                    severity={alert.severity as AlertSeverity}
                    sx={{
                        width: "100%",
                    }}
                >
                    {alert.text}
                </Alert>
            ) : null}

            {accountActivated ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                        gap: "20px",
                        width: "100%",
                        marginTop: "20px",
                        fontWeight: "bold",
                        textAlign: "center",
                    }}
                >
                    <LinearProgress color="inherit" />
                    <p>Laddar...</p>
                </Box>
            ) : (
                <>
                    <form onSubmit={handleActivate}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                gap: "10px",
                                width: "100%",
                            }}
                        >
                            <TextField
                                fullWidth={!inline}
                                label="Aktiveringskod"
                                name="activationCode"
                                variant="standard"
                                value={activationCode}
                                onChange={handleChange}
                                error={!!error}
                                helperText={error}
                                disabled={accountActivated || handlingSubmit}
                            />
                            <BasicButton title="Aktivera" type="submit" disabled={!activationCode || accountActivated || handlingSubmit} progress={handlingSubmit && !accountActivated} />
                        </Box>
                    </form>
                    <LinkButton label="Har du ingen aktiveringskod?" url="https://funki.se/#/contact" disabled={accountActivated || handlingSubmit} />
                </>
            )}
        </Box>
    )
}

export default ActivateAccount
