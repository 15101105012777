import { useState, ChangeEvent, FocusEvent, FormEvent } from "react"
import { Box, TextField, Button, Alert } from "@mui/material"
import * as Yup from "yup"
import { signUp } from "../firebase/authenticationApi"
import { FirebaseError } from "firebase/app"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "../utils/api"
import BasicButton from "../assets/components/BasicButton"

interface FormData {
    name?: string | null | undefined
    email: string
    password: string
}

type AlertColor = "error" | "warning" | "info" | "success"

const validationSchema: Yup.Schema<FormData> = Yup.object().shape({
    name: Yup.string().max(50, "Max 50 karaktärer långt").notRequired(),
    email: Yup.string().email("Ogiltig e-postadress").required("Obligatorisk"),
    password: Yup.string().required("Obligatorisk").min(8, "Måste vara minst 8 karaktärer"),
})

const SignUp = () => {
    const initialFormState: FormData = {
        name: "",
        email: "",
        password: "",
    }

    const initialErrors: FormData = {
        name: "",
        email: "",
        password: "",
    }

    const initialAlert = {
        severity: "",
        text: "",
    }

    const navigate = useNavigate()
    const [formData, setFormData] = useState(initialFormState)
    const [errors, setErrors] = useState(initialErrors)
    const [alert, setAlert] = useState(initialAlert)
    const [handlingSubmit, setHandlingSubmit] = useState(false)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleBlur = async (e: FocusEvent<HTMLInputElement>) => {
        const name = e.target.name
        const value = e.target.value

        try {
            await (validationSchema as Yup.ObjectSchema<FormData>).validateAt(name, { [name]: value })
            setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }))
        } catch (error: any) {
            if (Yup.ValidationError.isError(error)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: error.errors[0],
                }))
            }
        }
    }

    const handleSignUp = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        // Indicate handling submit
        setHandlingSubmit(true)

        // Reset alert
        setAlert(initialAlert)

        try {
            // Validate form data
            await validationSchema.validate(formData, { abortEarly: false })
            setErrors(initialErrors) // Reset errors

            // Sign up user
            await signUp(formData.name, formData.email, formData.password)

            // Report success
            setAlert({ severity: "success", text: "Skapade användare!" })
            setFormData(initialFormState) // Reset form
        } catch (error: any) {
            if (Yup.ValidationError.isError(error)) {
                // Validation error
                const newErrors: FormData = { ...initialErrors }
                error.inner.forEach((err: any) => {
                    newErrors[err.path as keyof FormData] = err.message
                })
                setErrors(newErrors)

                // Report validation failure
                setAlert({ severity: "error", text: "Det finns fel i formuläret nedan" })
            } else if (error instanceof FirebaseError) {
                // Firebase error
                switch (error.code) {
                    case "auth/email-already-in-use":
                        // Report email already in use error
                        setAlert({ severity: "error", text: "Det finns redan ett konto kopplat till e-postadressen" })
                        break
                    case "auth/weak-password":
                        // Report weak password error
                        setAlert({ severity: "error", text: "Lösenordet är för svagt eller vanligt, välj ett annat lösenord" })
                        break
                    default:
                        // Report general Firebase error
                        setAlert({ severity: "error", text: "Misslyckades att skapa användare..." })
                }
            } else {
                // Other error
                // Report general failure
                setAlert({ severity: "error", text: "Misslyckades att skapa användare..." })
            }
        }

        // Indicate done handling submit
        setHandlingSubmit(false)
    }

    return (
        <Box
            sx={{
                paddingTop: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                height: "100%",
                width: "330px",
                margin: "0 auto",
                padding: "16px",
            }}
        >
            <h4>Skapa konto</h4>
            {alert.text ? (
                <Alert
                    severity={alert.severity as AlertColor}
                    onClose={() => {
                        setAlert(initialAlert)
                    }}
                >
                    {alert.text}
                </Alert>
            ) : null}
            <form onSubmit={handleSignUp}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "10px",
                    }}
                >
                    <TextField
                        fullWidth
                        label="Namn (valfri)"
                        name="name"
                        variant="standard"
                        value={formData.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.name}
                        helperText={errors.name}
                        disabled={handlingSubmit}
                    />
                    <TextField
                        fullWidth
                        label="E-postadress"
                        name="email"
                        variant="standard"
                        value={formData.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.email}
                        helperText={errors.email}
                        disabled={handlingSubmit}
                    />
                    <TextField
                        fullWidth
                        label="Lösenord"
                        name="password"
                        type="password"
                        variant="standard"
                        value={formData.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.password}
                        helperText={errors.password}
                        disabled={handlingSubmit}
                    />
                    <BasicButton title="Skapa konto" type="submit" disabled={handlingSubmit} progress={handlingSubmit} />
                </Box>
            </form>
            <Button
                sx={{
                    textTransform: "none", // Prevent uppercase text
                    color: "black", // Set link color
                    textDecoration: "underline", // Add underline
                    padding: 0, // Remove padding
                    minWidth: "initial", // Allow the button to shrink to the content size
                    background: "none", // Remove background color
                    border: "none", // Remove border
                    cursor: "pointer", // Change cursor to pointer on hover
                    marginTop: "16px",
                }}
                onClick={() => {
                    navigate(ROUTES.LOG_IN)
                }}
            >
                Tillbaka till Logga in
            </Button>
        </Box>
    )
}

export default SignUp
