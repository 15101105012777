import { useState, ChangeEvent, FormEvent } from "react"
import { TextField, Button, Alert, Box } from "@mui/material"
import * as Yup from "yup"
import { startPasswordReset } from "../firebase/authenticationApi"
import { FirebaseError } from "firebase/app"
import { ROUTES } from "../utils/api"
import { useNavigate } from "react-router-dom"
import BasicButton from "../assets/components/BasicButton"

type AlertColor = "error" | "warning" | "info" | "success"

const RequestPasswordReset = () => {
    const emailSchema: Yup.Schema<string> = Yup.string().email("Ogiltig e-postadress").required("Fyll i din e-postadress")

    const initialEmailState = ""
    const initialError = ""
    const initialAlert = {
        severity: "",
        text: "",
    }

    const [email, setEmail] = useState(initialEmailState)
    const [error, setError] = useState(initialError)
    const [alert, setAlert] = useState(initialAlert)
    const [sentEmail, setSentEmail] = useState(false)
    const [handlingSubmit, setHandlingSubmit] = useState(false)
    const navigate = useNavigate()

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
    }

    const handleRequestPasswordReset = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        // Indicate handling submit
        setHandlingSubmit(true)

        // Reset alert
        setAlert(initialAlert)

        try {
            // Validate form data
            await emailSchema.validate(email, { abortEarly: false })
            setError(initialError) // Reset email error

            // Start password reset
            await startPasswordReset(email)

            // Sent email
            setSentEmail(true)

            // Report success
            setAlert({
                severity: "success",
                text: `Vi har skickat en länk till ${email}. Klicka på länken och följ sedan instruktionerna i det nya fönstret som öppnas. Om inget mail dyker upp, se till att kolla i skräppost-mappen.`,
            })
        } catch (error: any) {
            if (Yup.ValidationError.isError(error)) {
                // Validation error
                setError(error.message)

                // Report validation failure
                setAlert({ severity: "error", text: "Det finns fel i formuläret nedan" })
            } else if (error instanceof FirebaseError) {
                // Firebase error
                // Don't let the user know the details here, pretend that it all went well (do not confirm user account existence)
                console.log("error.code", error.code)

                switch (error.code) {
                    case "auth/user-not-found":
                        // Sent email
                        setSentEmail(true)

                        // Feedback user
                        setAlert({
                            severity: "success",
                            text: `Vi har skickat en länk till ${email}. Klicka på länken och följ sedan instruktionerna i det nya fönstret som öppnas. Om inget mail dyker upp, se till att kolla i skräppost-mappen.`,
                        })
                        console.log("ERROR:", error.message)
                        break
                    default:
                        // Sent email
                        setSentEmail(true)

                        // Feedback user
                        setAlert({
                            severity: "success",
                            text: `Vi har skickat en länk till ${email}. Klicka på länken och följ sedan instruktionerna i det nya fönstret som öppnas. Om inget mail dyker upp, se till att kolla i skräppost-mappen.`,
                        })
                        console.log("ERROR:", error.message)
                }
            } else {
                // Other error
                // Don't let the user know the details here, pretend that it all went well (do not confirm user account existence)

                // Sent email
                setSentEmail(true)

                // Feedback user
                setAlert({
                    severity: "success",
                    text: `Vi har skickat en länk till ${email}. Klicka på länken och följ sedan instruktionerna i det nya fönstret som öppnas. Om inget mail dyker upp, se till att kolla i skräppost-mappen.`,
                })
                console.log("ERROR:", error.message)
            }
        }

        // Indicate done handling submit
        setHandlingSubmit(false)
    }

    return (
        <Box
            sx={{
                paddingTop: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                height: "100%",
                width: "330px",
                margin: "0 auto",
                padding: "16px",
            }}
        >
            <h4>Glömt ditt lösenord?</h4>
            <p>Fyll i din e-postadress så skickar vi en länk för att du ska kunna välja ett nytt lösenord.</p>

            {alert.text ? <Alert severity={alert.severity as AlertColor}>{alert.text}</Alert> : null}

            <form onSubmit={handleRequestPasswordReset}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "10px",
                    }}
                >
                    <TextField
                        fullWidth
                        label="E-postadress"
                        name="email"
                        variant="standard"
                        value={email}
                        onChange={handleChange}
                        error={!!error}
                        helperText={error}
                        disabled={sentEmail || handlingSubmit}
                    />
                    <BasicButton title="Fortsätt" type="submit" disabled={sentEmail || handlingSubmit} progress={handlingSubmit} />
                </Box>
            </form>
            <Button
                sx={{
                    textTransform: "none", // Prevent uppercase text
                    color: "black", // Set link color
                    textDecoration: "underline", // Add underline
                    padding: 0, // Remove padding
                    minWidth: "initial", // Allow the button to shrink to the content size
                    background: "none", // Remove background color
                    border: "none", // Remove border
                    cursor: "pointer", // Change cursor to pointer on hover
                    marginTop: "16px",
                }}
                onClick={() => {
                    navigate(ROUTES.LOG_IN)
                }}
            >
                Tillbaka till Logga in
            </Button>
        </Box>
    )
}

export default RequestPasswordReset
