import { Box, Divider, Grid, Stack } from "@mui/material"
import CurrentlyPlaying from "../../components/CurrentlyPlaying"
import { useIsSmallScreen } from "../../hooks/useIsSmallScreen"
import Text from "./Text"

interface MenuWithContentProps {
    menuItems: React.ReactNode
    content: React.ReactNode
    showCurrentlyPlaying?: boolean
    extraPadding?: boolean
    heading?: string
}

const MenuWithContent = ({ menuItems, content, showCurrentlyPlaying, heading }: MenuWithContentProps) => {
    const isSmallScreen = useIsSmallScreen()
    return isSmallScreen ? (
        <MobileMenu menuItems={menuItems} content={content} showCurrentlyPlaying={showCurrentlyPlaying} heading={heading} />
    ) : (
        <DesktopMenu menuItems={menuItems} content={content} showCurrentlyPlaying={showCurrentlyPlaying} />
    )
}

const MobileMenu = ({ menuItems, content, heading }: MenuWithContentProps) => {
    return (
        <Grid container direction="column" sx={{ height: "90%" }}>
            <Grid
                item
                sx={{
                    margin: 1,
                }}
            >
                {heading && (
                    <Text noWrap size={25}>
                        {heading}
                    </Text>
                )}
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 1, pb: 2, px: 1 }}>{menuItems}</Box>
            </Grid>

            <Grid item xs sx={{ overflow: "auto", height: "80%", padding: "0px 2rem 5rem 2rem" }}>
                {content}
            </Grid>
        </Grid>
    )
}

const DesktopMenu = ({ menuItems, content, showCurrentlyPlaying }: MenuWithContentProps) => {
    return (
        <Grid container sx={{ height: "100%" }}>
            <Grid
                item
                sx={{
                    minWidth: "150px",
                    padding: "32px 24px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <Stack sx={{ height: "100%", gap: 2 }}>
                    <Grid item xs sx={{ overflow: "auto" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "10px", pt: 5 }}>{menuItems}</Box>
                    </Grid>
                    {showCurrentlyPlaying && (
                        <Grid item xs="auto">
                            <Box>
                                <CurrentlyPlaying />
                            </Box>
                        </Grid>
                    )}
                </Stack>
            </Grid>
            <Grid item sx={{ padding: "32px 0px", height: "100%" }}>
                <Divider orientation="vertical" sx={{ borderColor: "#808080" }} />
            </Grid>
            <Grid item xs sx={{ overflow: "auto", height: "100%", padding: "5rem 2rem 0rem 2rem" }}>
                {content}
            </Grid>
        </Grid>
    )
}

export default MenuWithContent
