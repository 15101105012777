import { Box } from "@mui/material"
import { useCurrentUser } from "../../hooks/contexts/currentUserContext"
import TextParagraph from "../../assets/components/TextParagraph"
import ActivateAccount from "../ActivateAccount"
import { Instrument } from "../../utils/types"

type PaymentPlan = keyof typeof PaymentPlans

const PaymentPlans = {
    monthly: "Månadsvis",
    quarterly: "Kvartalsvis",
    yearly: "Årsvis",
}

const instrumentsWithNumbers = (instruments: Instrument[] | undefined) => {
    return instruments
        ?.reduce((result: string, instrument: Instrument) => {
            const type = instrument.type
            const number = 1 + (result.toLowerCase().match(new RegExp(type, "g")) || []).length
            const delimiter = result !== "" ? "," : ""
            return result + delimiter + type + " " + number
        }, "")
        .toLowerCase()
        .split(",")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .sort()
}

const sortOnType = (a: Instrument, b: Instrument) => {
    if (a.type < b.type) {
        return -1
    }
    if (a.type > b.type) {
        return 1
    }
    return 0
}

const License = () => {
    const currentUser = useCurrentUser()
    const alias = currentUser?.customer?.alias as string
    const paymentPlan = currentUser?.customer?.paymentPlan as PaymentPlan
    const instruments = currentUser?.customer?.instruments?.sort(sortOnType) as Instrument[]

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "20px" }}>
            {currentUser?.customer ? (
                <>
                    <TextParagraph heading="Licensägare" body={alias} />

                    <TextParagraph heading="Betalplan" body={paymentPlan && PaymentPlans[paymentPlan]} />

                    {instruments ? (
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "10px" }}>
                            <TextParagraph heading="Instrument som kopplats" />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "flex-start",
                                    gap: "20px",
                                    flexWrap: "wrap",
                                }}
                            >
                                {instrumentsWithNumbers(instruments)?.map((instrument, index) => {
                                    return <TextParagraph key={index} body={instrument} />
                                })}
                            </Box>
                        </Box>
                    ) : (
                        <TextParagraph heading="Instrument som kopplats" body="Inga instrument har kopplats" />
                    )}
                </>
            ) : (
                <ActivateAccount inline />
            )}
        </Box>
    )
}

export default License
