import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Box, Grid, Stack } from "@mui/material"
import { SONG_TYPES, Song } from "../utils/types"
import BasicToggleButtonGroup from "../assets/components/BasicToggleButtonGroup"
import { useCurrentUser } from "../hooks/contexts/currentUserContext"

interface CategorySelectorProps {
    category: string | undefined
    setCategory: Dispatch<SetStateAction<string | undefined>>
    type: SONG_TYPES
}

type Categories = {
    soundbanks: string[]
    music: string[]
    key: string[]
    scale: string[]
    [key: string]: any
}

const getCategories = (songList: Song[]) => {
    let categories: Categories = { soundbanks: [], music: [], key: [], scale: [] }

    // Get all categories from song list
    songList.forEach((song) => {
        // If song category not in the list yet, add it
        if (!categories[song.type].includes(song.category)) {
            categories[song.type].push(song.category)
        }
    })

    return categories
}

const CategorySelector = ({ category, setCategory, type }: CategorySelectorProps) => {
    const [reset, setReset] = useState<boolean>(false)
    const songList = useCurrentUser()?.songList || []
    let categories = getCategories(songList)

    useEffect(() => {
        setReset((prev) => !prev)
    }, [type])

    return (
        <Stack direction={"column"} sx={{ width: "100%", gap: 1 }}>
            {/* <Grid item xs="auto">
                <Text noWrap bold size={20}>
                    {type === SONG_TYPES.MUSIC ? "Visa genre:" : "Visa kategori:"}
                </Text>
            </Grid> */}
            <Grid item xs>
                <Box>
                    {/* {type === SONG_TYPES.MUSIC ? (
                        <BasicToggleButtonGroup items={["alla", ...categories.music]} defaultSelected={"alla"} selected={category} setSelected={setCategory} reset={reset} />
                    ) : (
                        <>
                            <BasicToggleButtonGroup items={["alla", ...categories.soundbanks]} defaultSelected={"alla"} selected={category} setSelected={setCategory} reset={reset} />
                        </>
                    )} */}
                    {type === SONG_TYPES.MUSIC && <BasicToggleButtonGroup items={["alla", ...categories.music]} defaultSelected={"alla"} selected={category} setSelected={setCategory} reset={reset} />}
                    {type === SONG_TYPES.SOUNDBANKS && (
                        <BasicToggleButtonGroup items={["alla", ...categories.soundbanks]} defaultSelected={"alla"} selected={category} setSelected={setCategory} reset={reset} />
                    )}
                    {type === SONG_TYPES.KEY && <BasicToggleButtonGroup items={["alla", ...categories.key]} defaultSelected={"alla"} selected={category} setSelected={setCategory} reset={reset} />}
                </Box>
            </Grid>
        </Stack>
    )
}

export default CategorySelector
