import React, { useState } from "react"
import { Box } from "@mui/material"
import Text from "../../assets/components/Text"
import track from "../../audio/dyr-A-backingtrack.mp3"
const AudioPlayer = () => {
    // const [isPlaying, setIsPlaying] = useState(false)

    // const togglePlay = () => {
    //     setIsPlaying(!isPlaying)
    // }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
            <Text size={"heading"} bold>
                Bakgrundsspår
            </Text>
            {/* <button onClick={togglePlay}>{isPlaying ? "Pause" : "Play"}</button> */}
            <audio src={track} controls />
        </Box>
    )
}

export default AudioPlayer
