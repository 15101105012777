import { useEffect, useState } from "react"
import { useSwipeable } from "react-swipeable"
import { Grid, Stack, useMediaQuery } from "@mui/material"
import { SONG_CATEGORIES, SONG_TYPES, Song } from "../utils/types"
import SongCard from "./Card"
import PaginationControls from "../assets/components/PaginationControls"
import { useCurrentUser } from "../hooks/contexts/currentUserContext"

const DisplayedCards = ({ displayedSongs, page, cardsPerPage }: { displayedSongs: Song[]; page: number; cardsPerPage: number }) => {
    const [currentCards, setCurrentCards] = useState<Song[]>([])

    useEffect(() => {
        const startIndex = (page - 1) * cardsPerPage
        const endIndex = startIndex + cardsPerPage
        const updatedCurrentCards = displayedSongs.slice(startIndex, endIndex)

        setCurrentCards(updatedCurrentCards)
    }, [displayedSongs, page, cardsPerPage])

    return (
        <Grid container spacing={3} sx={{ height: "auto", alignItems: "space-between" }}>
            {currentCards.map((song) => (
                <Grid key={song.id} item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <SongCard song={song} />
                </Grid>
            ))}
        </Grid>
    )
}

interface ChooseSongProps {
    type: SONG_TYPES
    searchValue?: string
    category?: string | undefined
}

const ChooseSong = ({ type, category, searchValue }: ChooseSongProps) => {
    const songList = useCurrentUser()?.songList || []
    const [page, setPage] = useState<number>(1)
    const [filteredSongs, setFilteredSongs] = useState<Song[]>([])
    const [pageCount, setPageCount] = useState<number>(0)
    const [cardsPerPage, setCardPerPage] = useState<number>(0)

    const [swipeDirection, setSwipeDirection] = useState<"left" | "right" | null>(null)

    let displayedSongs: Song[] = filteredSongs

    // console.log("filteredSongs ", filteredSongs)

    const handleSwipe = (direction: "left" | "right") => {
        setSwipeDirection(direction)
        setTimeout(() => {
            setSwipeDirection(null)
        }, 200)
    }

    // Swipe handlers
    const swipeHandlers = useSwipeable({
        onSwipedRight: () => {
            if (page !== 1) {
                setPage(page - 1)
                handleSwipe("right")
            }
        },
        onSwipedLeft: () => {
            if (page !== pageCount) {
                setPage(page + 1)
                handleSwipe("left")
            }
        },
        preventScrollOnSwipe: true,
        trackMouse: true,
    })

    // Listen to screen width
    const isExtraSmallScreen = useMediaQuery("(max-width:600px)")
    const isSmallScreen = useMediaQuery("(max-width:900px)")
    const isMediumScreen = useMediaQuery("(max-width:1200px)")
    const isLargeScreen = useMediaQuery("(max-width:1536px)")
    const isExtraLargeScreen = useMediaQuery("(min-width:1536px)")

    if (searchValue !== undefined) {
        const formattedSearchValue = searchValue.trim().toLowerCase()
        displayedSongs = filteredSongs.filter((song: Song) => {
            // console.log(song)
            return song.title.toLowerCase().includes(formattedSearchValue)
        })
    }

    useEffect(() => {
        const updatedFilteredSongs = songList
            .slice()
            .filter((song) => {
                return song.type === type
            })
            .filter((song) => {
                return song.category === category || category === SONG_CATEGORIES.ALL
            })
        setFilteredSongs(updatedFilteredSongs)
    }, [type, category])

    useEffect(() => {
        const updatedPageCount = Math.max(1, Math.ceil(filteredSongs.length / cardsPerPage))

        if (updatedPageCount) {
            // Avoid page being out of bounds
            if (page > updatedPageCount) {
                setPage(updatedPageCount)
            }

            // Update state
            setPageCount(updatedPageCount)
        }
    }, [filteredSongs, cardsPerPage, page])

    useEffect(() => {
        const updatedCardsPerPage: number = isExtraSmallScreen ? 2 : isSmallScreen ? 2 : isMediumScreen ? 4 : isLargeScreen ? 9 : isExtraLargeScreen ? 12 : 12
        setCardPerPage(updatedCardsPerPage)
    }, [isExtraSmallScreen, isSmallScreen, isMediumScreen, isLargeScreen, isExtraLargeScreen])

    return (
        <Stack sx={{ height: "100%" }}>
            <Grid
                item
                xs
                {...swipeHandlers}
                sx={{
                    overflow: "auto",
                    padding: 1,
                    transition: "transform 0.3s ease-in-out",
                    transform: swipeDirection === "left" ? "translateX(-100%)" : swipeDirection === "right" ? "translateX(100%)" : "none",
                }}
            >
                <DisplayedCards displayedSongs={displayedSongs} page={page} cardsPerPage={cardsPerPage} />
            </Grid>
            <Grid item sx={{ overflow: "auto", display: "flex", position: "static", bottom: 0, top: "auto", justifyContent: "center", mb: 1 }}>
                <PaginationControls pageCount={pageCount} page={page} setPage={setPage} />
            </Grid>
        </Stack>
    )
}

export default ChooseSong
